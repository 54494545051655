





























import { useAppsStore } from "../store/apps";
import { Component, Vue } from "vue-property-decorator";
import { getPlistLink } from "../utils";
import AppCard from "../components/AppCard.vue"; // @ is an alias to /src

@Component({
  components: {
    AppCard,
  },
})
export default class Home extends Vue {
  appsStore = useAppsStore();

  async mounted(): Promise<void> {
    this.refreshAppList();
  }

  async refreshAppList(): Promise<void> {
    this.appsStore.refreshApps();
  }

  getFormattedPlistLink(id: string): string {
    return getPlistLink(id);
  }
}
